import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Route, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

// This component implements the main menu.
// As outlined in https://www.w3.org/WAI/ARIA/apg/example-index/menubar/menubar-navigation.html
@Component({
  selector: 'lib-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    CommonModule,
  ],
})
export class MainMenuComponent {
  @Input() routes!: Routes;

  canShowMenu(route: Route): boolean {
    // just show all the routes your sent
    return true;
  }
}
