import {
  Component,
  HostListener,
  Inject,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import {
  Router,
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  NavigationEnd,
  RouterModule,
  ActivatedRoute,
} from '@angular/router';
import { CommonModule, isPlatformBrowser, NgClass } from '@angular/common';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { ScrollToTopComponent } from './shared/components/scroll-to-top/scroll-to-top.component';
import { AdminLayoutComponent } from './shared/layout/admin-layout/admin-layout.component';
import { CuNgDesignLibraryComponent } from './shared/layout/cu-ng-design-library/cu-ng-design-library.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderButtonsComponent } from './shared/layout/header-buttons/header-buttons.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { MainFooterComponent } from './shared/layout/main-footer/main-footer.component';
import { MainMenuComponent } from './shared/layout/main-menu/main-menu.component';
import { MobileMenuComponent } from './shared/layout/mobile-menu/mobile-menu.component';
import { SubFooterComponent } from './shared/layout/sub-footer/sub-footer.component';
import { SkipLinkComponent } from './shared/components/skip-link/skip-link.component';
import { AppRoute } from './app.routes';
import { routes } from './app.routes';
import { MatIconModule } from '@angular/material/icon';
import { ResourcesHomeComponent } from './components/public/resources/resources-home.component';
import { filter, takeUntil } from 'rxjs/operators'; // Import takeUntil
import { ExtendedProfile } from '../../types/types';
import { HomeSliderComponent } from './shared/components/home-slider/home-slider.component';
import { AdminRoutes } from './admin.routes';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { ErrorEventService } from './shared/services/error.event.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { Guard } from './shared/guards/guard';
import { SlideComponent } from './shared/components/slide/slide.component';
import { Subject } from 'rxjs'; // Import Subject

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [ErrorDialogComponent, AuthenticationService],
  imports: [
    MatSidenavContainer,
    CommonModule,
    NgClass,
    RouterOutlet,
    RouterLink,
    SkipLinkComponent,
    CuNgDesignLibraryComponent,
    HeaderComponent,
    HeaderButtonsComponent,
    MatButtonModule,
    RouterLinkActive,
    MobileMenuComponent,
    MainMenuComponent,
    FooterComponent,
    MainFooterComponent,
    SubFooterComponent,
    AdminLayoutComponent,
    ScrollToTopComponent,
    MatIconModule,
    ResourcesHomeComponent,
    HomeSliderComponent,
    RouterModule,
    SlideComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = signal(false);
  isAdminUser = signal(false);
  readonly publicRoutes: AppRoute[] = routes.filter((r) =>
    r.data.menu.includes('main')
  );
  readonly publicMobileRoutes: AppRoute[] = routes.filter((r) =>
    r.data.menu.includes('mobile')
  );
  readonly adminRoutes: AppRoute[] = AdminRoutes.filter((r) =>
    r.data.menu.includes('admin')
  );
  isPublicLayout = signal(false);
  readonly routes = routes;
  isHomePage = signal(false);
  isDetailPage = signal(false);
  isLoading = signal(true);
  userProfile = signal<ExtendedProfile | null>(null);
  isMobile = signal(false); // Initialize isMobile signal

  private destroy$ = new Subject<void>(); // Subject to manage unsubscription

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private errorEventService: ErrorEventService,
    private errorComponent: ErrorDialogComponent,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute
  ) {
    const data = this.activatedRoute.root.firstChild?.snapshot.data;
    if (data) {
      this.isHomePage.set(data.title === 'Home');
      this.isDetailPage.set(!this.isHomePage());
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile.set(window.innerWidth < 868);
    }
    this.getRoles();
    this.subscribeToRouterEvents();
    this.listenForErrorEvents();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile.set(event.target.innerWidth < 868);
    }
  }

  // good improvement here for page flash
  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$) // Unsubscribe on component destroy
      )
      .subscribe((event: any) => {
        const url = event.urlAfterRedirects; // Use urlAfterRedirects to ensure you get the final URL in case of redirects.

        // Check if the URL is for the home page.
        this.isHomePage.set(url === '/' || url.includes('/home'));

        // Check if the URL is for a details page.
        this.isDetailPage.set(url.includes('/detail'));

        this.isLoading.set(false);
        this.checkLayout();
      });
  }

  private listenForErrorEvents() {
    this.errorEventService.errorOccurred$
      .pipe(takeUntil(this.destroy$)) // Unsubscribe on component destroy
      .subscribe((error) => {
        this.errorComponent.showErrorMessage(
          error.error.message || error.message || error
        );
      });
  }

  private getRoles() {
    if (this.authenticationService.isBrowserOnly) {
      this.authenticationService
        .checkProfile()
        .pipe(takeUntil(this.destroy$)) // Unsubscribe on component destroy
        .subscribe((response) => {
          // console.log('checkProfile', response);
          if (response.data && response.status === 200) {
            this.userProfile.set(response.data);
            if (response.data.roles) {
              this.isAdminUser.set(
                Guard.roleCanViewAdminPolicy(response.data.roles)
              );
            }
            this.isLoggedIn.set(true);
          }
        });
    }
  }

  checkLayout() {
    const url = this.router.url; // Get the current route path
    const admin = url.split('/')[1];
    const isPublic = admin !== 'admin';

    // Update only if there is a change
    if (this.isPublicLayout() !== isPublic) {
      this.isPublicLayout.set(isPublic);
    }
  }
}
