<div class="main-footer">
  <div class="container-fluid sidebar-left">
    <div class="flex gap-6 flex-direction-row-reverse">
      <div class="primary flex-2">
        <ng-content select="[primary]"></ng-content>
      </div>
      <div class="secondary flex-1">
        <ng-content select="[secondary]"></ng-content>
      </div>
    </div>
  </div>
</div>
