import { SecondaryNavComponent } from './../../../features/admin/secondary-nav/secondary-nav.component';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { RouterModule, Routes } from '@angular/router';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { AppRoute } from '../../../../app/app.routes';
import { SimpleSideMenuComponent } from '../simple-side-menu/simple-side-menu.component';
import { PageOutletComponent } from '../../../../app/features/admin/page-outlet/page-outlet.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'lib-admin-layout',
  standalone: true,
  imports: [
    RouterModule,
    SideMenuComponent,
    SimpleSideMenuComponent,
    SecondaryNavComponent,
    PageOutletComponent,
  ],
  template: `
    <lib-simple-side-menu
      [routes]="routes"
      [title]="toolbarTitle"
      [svgIconUrl]="svgIconUrl"
      [imgHeight]="imgHeight"
      [showToggle]="true"
      [mode]="mode"
      [opened]="opened"
      [isMobileLayout]="isMobileLayout"
    >
      <ng-content ngProjectAs="[toolbar]"></ng-content>
      <app-page-outlet></app-page-outlet>
    </lib-simple-side-menu>
    <!-- @todo implement this -->
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class AdminLayoutComponent implements OnInit {
  @Input() routes!: AppRoute[];
  @Input() toolbarTitle = 'SSIT';
  @Input() svgIconUrl =
    '../../assets/images/bold_cornell_seal_simple_white.svg';
  @Input() imgHeight = '45px';
  @Input() mode: MatDrawerMode = 'side'; // 'over' | 'push' | 'side'
  @Input() opened = true;
  @Input() isMobileLayout = false;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  get isBrowserOnly(): boolean {
    return isPlatformBrowser(this.platformId);
  }
  ngOnInit(): void {
    if (this.isBrowserOnly) {
      this.isMobileLayout = window.innerWidth <= 768; // @todo use media queries
      if (this.isMobileLayout) {
        this.mode = 'over';
        this.opened = false;
      }
    }
  }
}
