<nav class="dropdown-menu" id="main-navigation" aria-label="Main Navigation">
  <div class="container-fluid">
    <ul class="list-menu links">
      @for (route of routes; track $index) {
      <li class="top-level-item parent top-level-li" routerLinkActive="active">
        <a mat-button routerLink="{{ route.path }}" routerLinkActive="active">
          {{ route.title ? route.title : route.path }}
        </a>
      </li>
      }
    </ul>
  </div>
</nav>
