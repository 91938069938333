import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Emits an error event to any subscribers.
 * Typically app.component.ts will subscribe to this service and display the error.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorEventService {
  private errorSubject = new Subject<any>();

  errorOccurred$ = this.errorSubject.asObservable();

  emitErrorEvent(error: any) {
    this.errorSubject.next(error);
  }
}
