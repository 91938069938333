<div [ngClass]="getClasses()">
  <div class="cu-ribbon"></div>
  <header id="cu-header">
    <ng-template [ngIf]="logo === 'cu-45'">
      <div class="cu45-helper">
        <a href="https://www.cornell.edu/" id="cu-brand" title="Cornell.edu Homepage">
          <div class="cu-logotype">Cornell University</div>
        </a>

      </div>
    </ng-template>
    <div class="container-fluid cu-brand">
      <h1 class="cu-logo">
        <a href="http://www.cornell.edu">
          <span
            class="sr-only"
            style="
              position: absolute;
              left: -10000px;
              top: auto;
              width: 1px;
              height: 1px;
              overflow: hidden;
            "
          >
            Cornell University
          </span>
        </a>
      </h1>
      <div class="cu-unit">
        @if(title) {
        <a routerLink="/">
          <h2>{{ title }}</h2>
        </a>
        }
        <ng-content select="[unit-title]"></ng-content>
        @if(subtitle) {
        <h3 class="sans mat-h3 desktop-only">
          {{ subtitle }}
        </h3>
        }
      </div>
      <ng-content></ng-content>
    </div>
  </header>
</div>
