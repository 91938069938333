import { Component, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneColumnComponent } from '../../../shared/layout/one-column/one-column.component';
import { AppRoute } from '../../../../app/app.routes';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-secondary-nav',
  standalone: true,
  imports: [
    CommonModule,
    OneColumnComponent,
    MatIconModule,
    RouterModule,
    MatListModule,
  ],
  template: `
    @if (navTitle) {
    <div class="bottom-border">
      <h3 class="p-2 m-0">{{ navTitle }}</h3>
    </div>
    } @for(route of summaryRoutes; track $index) {
    <a
      mat-list-item
      [routerLink]="baseUrl + '/' + route.path"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLinkActive="active"
    >
      <span class="full-flex align-items-center">
        <span>
          @if (route.data['icon']) {
          <mat-icon matListIcon>
            {{ route.data['icon'] }}
          </mat-icon>
          }
        </span>
        <span class="pl font-size-larger">
          {{
            route.data && route.data['title']
              ? route.data['title']
              : route.data['path'] || route.path
          }}
        </span>
      </span>
    </a>
    }
  `,
  styles: [
    `
      .bottom-border {
        border-bottom: 1px var(--mat-sidenav-container-divider-color, #444)
          solid;
      }
      .active {
        background: rgba(0, 0, 0, 0.2);
      }
    `,
  ],
})
export class SecondaryNavComponent {
  @Input({ required: true }) summaryRoutes!: AppRoute[];
  @Input() navTitle: string | undefined;
  @Input() baseUrl!: string; // Add this line
}
