import { Component, Inject, Input, OnInit, signal } from '@angular/core';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { OneColumnComponent } from '../../../shared/layout/one-column/one-column.component';
import { MatIconModule } from '@angular/material/icon';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { LocationInfoComponent } from '../../../../app/components/public/detail/location-info/location-info.component';
import { ImageIndexService } from './image-index-service';
import { SlideComponent } from '../slide/slide.component';
import {
  LocationExtended,
  LocationsService,
} from '../../../../app/shared/services/locations.service';
import { locations } from '@prisma/client';

@Component({
  selector: 'app-home-slider',
  standalone: true,
  providers: [LocationsService], // do not use Provider for ImageIndexService it should provided in root so that it is unique to the whole component lifecycle.
  imports: [
    CommonModule,
    OneColumnComponent,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    LocationInfoComponent,
    NgOptimizedImage,
    SlideComponent,
    RouterModule,
    RouterLink,
  ],
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent implements OnInit {
  @Input({ required: true }) isHomePage!: boolean;
  @Input() isDetailPage?: boolean;
  locations: LocationExtended[] = [];
  currentLocation: LocationExtended | null = null;
  currentLocationId: number | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private imageIndexService: ImageIndexService,
    private router: Router,
    private locationsService: LocationsService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.locationId) {
        const locationId = Number(params.locationId);
        if (!isNaN(locationId)) {
          this.currentLocationId = locationId;
          const location = this.findLocationById(locationId);
          if (location) {
            this.currentLocation = location;
            this.imageIndexService.setLocation(this.currentLocation);
          }
        }
      }
    });

    this.subscribeToLocationChange();
    this.getLocations();
  }

  subscribeToLocationChange() {
    this.imageIndexService.locationChanged$.subscribe((location) => {
      this.currentLocation = location;
      this.currentLocationId = location.locationId || null;
    });
  }

  getLocations() {
    this.locationsService.getLocations().subscribe((response: locations[]) => {
      this.locations = response.filter((loc) => loc.active).splice(0, 4); // take the first four locations for the slider
      const location = this.findLocationById(this.currentLocationId || -1);
      if (location) {
        this.currentLocation = location;
        this.imageIndexService.setLocation(this.currentLocation);
      } else if (!this.isDetailPage) {
        // Detail page will set the location change in the detail component.
        this.currentLocation = this.locations[0];
        this.locations[Math.floor(Math.random() * this.locations.length)];
        this.imageIndexService.setLocation(this.currentLocation);
      }
      // if (this.currentLocation) {
      //   this.preloadImage(this.currentLocation.fileName || '');
      // }
    });
  }

  setCurrentLocation(locationId: number) {
    this.currentLocationId = locationId;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { locationId: locationId },
      queryParamsHandling: 'merge',
    });
    const location = this.locations.find(
      (loc) => loc.locationId === locationId
    );
    if (location) {
      this.currentLocation = location;
      this.imageIndexService.setLocation(this.currentLocation);
    }
  }

  getPreviousLocationId(): number | null {
    const currentIndex = this.locations.findIndex(
      (loc) => loc.locationId === this.currentLocationId
    );
    const previousIndex =
      (currentIndex - 1 + this.locations.length) % this.locations.length;
    const index = this.locations[previousIndex]?.locationId || null;
    return index;
  }

  getNextLocationId(): number | null {
    const currentIndex = this.locations.findIndex(
      (loc) => loc.locationId === this.currentLocationId
    );
    const nextIndex = (currentIndex + 1) % this.locations.length;
    const index = this.locations[nextIndex]?.locationId || null;
    return index;
  }

  findLocationById(locationId: number): locations | undefined {
    return this.locations.find((loc) => loc.locationId === locationId);
  }

  // trackById(index: number, location: locations): number | undefined {
  //   return location.locationId;
  // }

  // This isnt needed since SSR will preload the images.
  // preloadImage(url: string) {
  //   const link = this.document.createElement('link');
  //   link.rel = 'preload';
  //   link.as = 'image';
  //   link.href = url;
  //   this.document.head.appendChild(link);
  // }
}
