import { Route } from '@angular/router';
import { AdminGuard } from './shared/guards/AdminGuard';
// Define the route data interface
export interface RouteData {
  menu: string[];
  [key: string]: any; // Allow other optional properties
}

export interface AppRoute extends Route {
  data: RouteData; // make data.menu required
  children?: AppRoute[];
}

const routes: AppRoute[] = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    data: {
      menu: [],
      title: 'Home',
    },
  },
  {
    path: 'default',
    redirectTo: 'home',
    pathMatch: 'full',
    data: {
      menu: [],
      title: 'Home',
    },
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./components/public/home/home.component').then(
        (m) => m.HomeComponent
      ),

    data: {
      title: 'Home',
      header: 'public',
      menu: ['main', 'mobile'],
    },
    title: 'Home',
  },
  {
    path: 'nearby',
    loadComponent: () =>
      import('./components/public/nearby/nearby.component').then(
        (m) => m.NearbyComponent
      ),
    data: {
      title: 'Campus Nature',
      header: 'public',
      menu: ['main', 'mobile'],
    },
    title: 'Campus Nature',
  },
  {
    path: 'get-started',
    loadComponent: () =>
      import('./components/public/overview/overview.component').then(
        (m) => m.OverviewComponent
      ),
    data: {
      title: 'Get Started',
      header: 'public',
      menu: ['main', 'mobile'],
    },
    title: 'Get Started',
  },
  {
    path: 'events',
    loadComponent: () =>
      import(
        './components/public/events/events-page/events-page.component'
      ).then((m) => m.EventsPageComponent),
    data: {
      title: 'Events',
      header: 'public',
      menu: ['main', 'mobile'],
    },
    title: 'Events',
  },
  {
    path: 'resources',
    loadComponent: () =>
      import('./components/public/resources/resources.component').then(
        (m) => m.ResourcesComponent
      ),
    data: {
      title: 'Resources',
      header: 'public',
      menu: ['main', 'mobile'],
    },
    title: 'Resources',
  },
  {
    path: 'detail/:locationId',
    loadComponent: () =>
      import('./components/public/detail/detail.component').then(
        (m) => m.DetailComponent
      ),
    data: {
      title: 'Location',
      header: 'public',
      menu: [],
    },
    title: 'Location',
  },

  {
    path: 'about',
    loadComponent: () =>
      import('./components/public/about/about.component').then(
        (m) => m.AboutComponent
      ),
    data: {
      title: 'About Us',
      header: 'public',
      menu: ['main', 'mobile'],
    },
    title: 'About Us',
  },

  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('./admin.routes').then((m) => m.AdminRoutes),
    data: {
      menu: [''],
    },
    title: 'Admin',
  },

  {
    path: 'login',
    loadComponent() {
      return import('./features/public/login/login.component').then(
        (m) => m.LoginComponent
      );
    },
    data: {
      menu: [],
    },
    title: 'Login',
  },
  {
    path: 'logout',
    pathMatch: 'full',
    loadComponent() {
      return import('./features/public/logout/logout.component').then(
        (m) => m.LogoutComponent
      );
    },
    data: {
      menu: [],
    },
    title: 'Logout',
  },
  {
    path: '403',
    loadComponent: () =>
      import(
        './shared/components/not-authorized/not-authorized.component'
      ).then((m) => m.NotAuthorizedComponent),
    data: {
      title: 'Not Authorized',
      menu: [],
    },
  },

  {
    path: '404',
    loadComponent: () =>
      import('./components/public/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    data: {
      title: 'Not Found',
      menu: [],
    },
  },

  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
    data: {
      menu: [],
    },
    title: 'Not Found',
  },
];

export { routes };
