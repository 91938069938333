import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lib-sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.scss'],
  encapsulation: ViewEncapsulation.None, // required to override innerHTML styles
  standalone: true,
  imports: [MatIconModule],
})
export class SubFooterComponent {
  @Input()
  message = `If you have a disability and are having trouble accessing information on this website or need materials in an alternate format, contact <a href="mailto:naturerx@cornell.edu">naturerx@cornell.edu</a> for assistance.`;
}
