import { Component, Optional, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule],
})
export class ErrorDialogComponent {
  error: any;
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialog: MatDialog
  ) {}

  public showErrorMessage(error: any, status?: number | string): Promise<any> {
    console.log(error);
    this.dialog.open(ErrorDialogComponent, {
      minWidth: 300,
      data: {
        errorMessage: error.message || error.error || error,
      },
    });

    return Promise.resolve(error.message || error);
  }
}
