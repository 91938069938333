import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-scroll-to-top',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  template: `
    @if (showButton) {
    <button mat-icon-button (click)="scrollToTop()" class="scroll-to-top">
      <mat-icon class="scroll-icon">keyboard_arrow_up</mat-icon>
    </button>
    }
  `,
  styles: [
    `
      .scroll-to-top {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 99;
        background-color: #fff; /* White background */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* A little shadow for depth */
        border-radius: 50%; /* Makes the button round */
        padding: 10px; /* Some padding to make the button a bit larger */
        transition: background-color 0.3s, transform 0.3s; /* Transition for smooth effects */
      }

      .scroll-to-top:hover {
        background-color: #f0f0f0; /* Slightly darker background on hover */
        transform: scale(1.05); /* Slightly enlarge the button on hover */
      }

      .scroll-icon {
        font-size: 24px; /* Make the arrow icon a bit bigger */
      }
    `,
  ],
})
export class ScrollToTopComponent implements OnInit {
  showButton = false;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  get isBrowserOnly(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    if (this.isBrowserOnly) {
      // Initial check to see if window is scrollable
      this.checkScrollPosition();
    }
  }

  @HostListener('window:scroll', []) // Listen to window scroll events
  checkScrollPosition() {
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.showButton = scrollPosition > 100; // Show button if scrolled more than 100px. Adjust this value as needed.
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
