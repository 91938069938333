import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorEventService } from '../services/error.event.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private errorEventService: ErrorEventService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  get isBrowserOnly(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Handle successful responses here if needed
          }
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 403) {
              console.log('AuthErrorInterceptor: 403');
              this.router.navigateByUrl('/403'); // Use Angular Router for navigation
            } else if (error.status === 401) {
              const loginUrl = '/login';
              if (this.isBrowserOnly) {
                console.log('AuthErrorInterceptor: 401');
                const { pathname: sourcePage } = location;
                console.log('sourcePage', sourcePage);
                const cleanedSourcePage = sourcePage.startsWith('/')
                  ? sourcePage.substring(1)
                  : sourcePage;
                this.router.navigate([loginUrl], {
                  queryParams: { source: cleanedSourcePage },
                }); // Navigate with query parameters including the attempted URL
              }
              console.log(
                'AuthErrorInterceptor: attempting to SSR admin route'
              );
            } else {
              this.errorEventService.emitErrorEvent(error);
            }
          }
        },
      })
    );
  }
}
