import { Component } from '@angular/core';

@Component({
  selector: 'lib-header-buttons',
  template: `
    <div class="buttons">
      <ng-content></ng-content>
      <!-- <button class="mobile-button" id="mobile-nav">Main Menu</button> -->
      <!-- <button class="mobile-button" id="cu-search-button">Toggle Search Form</button> -->
    </div>
  `,
  styleUrls: ['./header-buttons.component.scss'],
  standalone: true,
})
export class HeaderButtonsComponent {}
