<section
  class="slide-wrapper"
  aria-label="Home Slider of Campus Nature resources, showing resource information and a link to view more resource details."
>
  @if (isHomePage) {
  <h3 class="sans mat-h3 mobile-only mobile-subtitle p-1 z-1">
    Holistic well-being through connection with nature
  </h3>
  } @if (currentLocation && isHomePage) {
  <div class="container-fluid slide-section full-flex flex-direction-column">
    @if( currentLocation){
    <div
      class="pt-4 z-index-1 slide-card flex flex-direction-column justify-content-space-around"
    >
      <a
        [routerLink]="['/detail', currentLocation.locationId]"
        class="detail-link"
      >
        <span class="mat-headline-2 bg-text">{{ currentLocation.name }}</span>
        <span class="slide-short-description full-flex align-items-end">
          <span class="truncate-text">{{
            currentLocation.shortDescription
          }}</span>
          <span class="accent-arrow full-flex align-items-center">
            <mat-icon>arrow_forward</mat-icon>
          </span>
        </span>
      </a>
      <app-location-info
        class="desktop-only pb-1"
        [location]="currentLocation"
      ></app-location-info>
    </div>
    }
    <div class="full-flex jc-c ai-e flex-1">
      <!-- removed bottom padding because of jank issue -->
      <div class="full-flex gap-1 jc-c ai-e">
        <!-- Previous Link -->
        <a
          class="prev-button"
          routerLink="/"
          [queryParams]="{ locationId: getPreviousLocationId() }"
          queryParamsHandling="merge"
          aria-label="Go to previous location"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <!-- Next Link -->
        <a
          class="next-button"
          routerLink="/"
          [queryParams]="{ locationId: getNextLocationId() }"
          queryParamsHandling="merge"
          aria-label="Go to next location"
        >
          <mat-icon>chevron_right</mat-icon>
        </a>
        <!-- Dots Container -->
        <div class="dots-container">
          @for ( location of locations; track $index) {
          <a
            mat-mini-fab
            class="dot-button"
            [class.active]="location.locationId === +(currentLocationId || '')"
            routerLink="/"
            [queryParams]="{ locationId: location.locationId }"
            queryParamsHandling="merge"
            [attr.aria-label]="'Go to slide ' + ($index + 1)"
          >
            <mat-icon>lens</mat-icon>
          </a>
          }
        </div>
      </div>
    </div>
  </div>
  } @if (currentLocation){
  <app-slide
    [currentLocation]="currentLocation"
    [fullBackground]="isDetailPage || isHomePage"
  ></app-slide>
  }
</section>
