import { Component, Input, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LocationExtended } from '../../services/locations.service';

@Component({
  selector: 'app-slide',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  template: `
    @if(currentLocation){
    <div
      class="image-container"
      [ngClass]="{ 'default-header-image': !fullBackground }"
    >
      <!-- preferred size 1000 x 666 with ratio of 3:2 -->
      <img
        [ngSrc]="currentLocation.fileName || ''"
        alt="{{ currentLocation.photoAltText }}"
        ngOptimizedImage
        [priority]="true"
        [fill]="true"
        class="slide-image"
        preconnect
      />
    </div>
    }
  `,
  styles: [
    `
      :host {
        user-select: none;
      }
      .image-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        background-color: rgb(28, 33, 38);
        background-color: rgb(54, 63, 71);
        min-height: 630px; // 226 home-slider.slide-section min-height 404px + 226px for header
        max-height: 876px; // 226 home-slider.slide-section min-height 404px + 226px for header
        height: calc(100vh - 200px);
      }
      .slide-image {
        object-fit: cover;
      }

      @media (max-width: 768px) {
        // .image-container {
        //   height: calc(100vh);
        // }
      }
    `,
  ],
})
export class SlideComponent {
  @Input({ required: true }) currentLocation!: LocationExtended;
  @Input() fullBackground: boolean = false;
}
