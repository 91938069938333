<div class="sub-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="vertical-align">
        <mat-icon class="icon">accessibility_new</mat-icon>
        <p class="accessibility-message" [innerHTML]="message"></p>
      </div>
    </div>
  </div>
</div>
