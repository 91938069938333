import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { MobileDialogComponent } from '../mobile-dialog/mobile-dialog.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lib-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
  ],
})
export class MobileMenuComponent {
  showFiller = false;
  @Input() routes!: Routes;
  constructor(public dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(MobileDialogComponent, {
      backdropClass: 'mobile-dialog-backdrop',
      position: {
        top: '0',
        left: '0',
      },
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'mobile-dialog',
      data: {
        routes: this.routes,
      },
    });
  }
}
