import { Component } from '@angular/core';

// https://stackoverflow.com/questions/50836497/using-html-anchor-link-fragment-in-angular-6
@Component({
  selector: 'lib-skip-link',
  standalone: true,
  template: `
    <div id="skipnav">
      <!-- <a (click)="jumpLink('main')">Skip to main content</a> -->
      <a href="./#main" onclick="event.preventDefault(); location.hash='main';">
        Skip to main content
      </a>
    </div>
  `,
  styleUrls: ['./skip-link.component.scss'],
})
export class SkipLinkComponent {
  // jumpLink(element: any) {
  //   const main = document.getElementById(element) as HTMLElement;
  //   main.focus();
  //   main.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //     inline: "nearest",
  //   });
  // }
}
