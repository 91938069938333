import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { Routes, Route, RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AdminToolbarComponent } from '../admin-toolbar/admin-toolbar.component';
import { AppRoute } from '../../../app.routes';

@Component({
  selector: 'lib-simple-side-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    AdminToolbarComponent,
  ],
  templateUrl: './simple-side-menu.component.html',
  styleUrls: ['./simple-side-menu.component.scss'],
})
export class SimpleSideMenuComponent implements OnInit {
  @Input() routes!: AppRoute[];
  @Input() title = 'Admin Toolbar';
  @Input() showToggle = true;
  @Input() svgIconUrl =
    '../../assets/images/bold_cornell_seal_simple_white.svg';
  @Input() imgHeight = '45px';
  @Input() mode: MatDrawerMode = 'side'; // 'over' | 'push' | 'side'
  @Input() opened = true;
  @Input() isMobileLayout = false;

  constructor() {
    this.showToggle = true;
  }

  ngOnInit(): void {
    if (this.isMobileLayout) {
      this.mode = 'over';
      this.opened = false;
    }
  }

  handleLinkClick() {
    if (this.isMobileLayout) {
      this.handleToggleClick();
    }
  }

  handleToggleClick() {
    this.opened = !this.opened;
  }
}
