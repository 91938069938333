<div mat-dialog-title class="drk-dialog">
  <h1 class="sr-only">Mobile Menu</h1>
  <div mat-dialog-actions>
    @if(data.routes){
    <div>
      <button mat-icon-button aria-label="home" routerLink="/" mat-dialog-close>
        <mat-icon>home</mat-icon>
      </button>
    </div>
    }
    <div>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-content class="drk-dialog">
  <mat-nav-list>
    @for ( route of routes; track $index) {
    <mat-list-item
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="border-radius-0"
    >
      <a
        mat-button
        routerLink="{{ route.path }}"
        mat-dialog-close
        class="mat-button-link"
      >
        {{
          route.data && route.data["title"] ? route.data["title"] : route.path
        }}
      </a>
    </mat-list-item>
    }
  </mat-nav-list>
</div>
