@if(isPublicLayout()) {
<div [ngClass]="{ home: isHomePage(), detail: isDetailPage() }">
  <lib-skip-link></lib-skip-link>
  <lib-cu-ng-design-library>
    <lib-header
      [useGradient]="true"
      [logo]="isMobile() ? 'cu-45' : 'cu-seal'"
      subtitle="Holistic well-being through connection with nature"
      title="Nature Rx"
      theme="cu-gray"
      header
    >
      <lib-header-buttons>
        <div class="flex jc-e gap">
          @if(isLoggedIn()){
          <nav id="utility-navigation" aria-label="Supplementary Navigation">
            <ul class="list-menu links flex gap">
              @if(isAdminUser()) {
              <li>
                <a
                  class="link"
                  mat-flat-button
                  routerLink="/admin/location"
                  routerLinkActive="active"
                  color="primary"
                >
                  {{ userProfile()?.netid }}
                </a>
              </li>
              }
              <li>
                <a
                  class="link"
                  mat-flat-button
                  color="primary"
                  href="/api/logout"
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
          }

          <lib-mobile-menu [routes]="publicMobileRoutes"></lib-mobile-menu>
        </div>
      </lib-header-buttons>
    </lib-header>
    <lib-main-menu [routes]="publicRoutes" main-menu></lib-main-menu>
    <app-home-slider
      [isHomePage]="isHomePage()"
      [isDetailPage]="isDetailPage()"
    ></app-home-slider>
    @if(isHomePage()){
    <app-resources-home secondary></app-resources-home>
    }
    <lib-footer footer>
      <lib-main-footer>
        <div primary>
          <div class="flex gap-4">
            <div class="flex-1">
              <h3 class="h6">Nature Rx &#64; Cornell</h3>
              <ul class="list-menu vertical">
                <li>
                  <a
                    href="https://health.cornell.edu/resources/health-topics/nature-rx"
                    target="_blank"
                  >
                    Nature Rx &#64; Cornell Health
                  </a>
                </li>
                <li>
                  <a
                    href="https://cornell.campusgroups.com/naturerx/home/ "
                    target="_blank"
                  >
                    Nature Rx Cornell Student Organization
                  </a>
                </li>
                <li>
                  <a
                    href="https://healthpromotingcampus.cornell.edu/"
                    target="_blank"
                  >
                    Cornell University Health Promoting Campus
                  </a>
                </li>
                <li>
                  <a
                    href="https://cals.cornell.edu/american-indian-indigenous-studies/about/land-acknowledgment"
                    target="_blank"
                  >
                    Cornell University Land Acknowledgement
                  </a>
                </li>
                <li>
                  <a
                    href="https://health.cornell.edu/resources/health-topics/climate-change"
                    target="_blank"
                  >
                    Climate Change & Eco-Anxiety information
                  </a>
                </li>
              </ul>
            </div>
            <div class="flex-1">
              <h3 class="h6">Opportunities to Learn More</h3>
              <ul class="list-menu vertical">
                <li>
                  <a href="https://www.campusnature.com/" target="_blank">
                    Campus Nature Rx Network
                  </a>
                </li>
                <li>
                  <a href="https://parkrxamerica.org/" target="_blank">
                    Park Rx America
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div secondary class="scl-watermark">
          <!-- <h2 class="h5">Contact Us</h2>
          <p>
            Nature Rx &#64; Cornell
            <br />
            Cornell University
            <br />
            Ithaca, NY 14853
            <br />
            <a href="mailto:naturerx@cornell.edu">naturerx&#64;cornell.edu</a>
          </p> -->
          <!-- <img src="/assets/images/White Word Mark Stack.png" alt="Student And Campus Life" /> -->
          <h2>
           <img src="/assets/images/SCL White Word Mark Horizontal.png" alt="Student And Campus Life" />
          </h2>
          <ul class="list-menu vertical">
            <li><a href="https://cornellbotanicgardens.org/" target="_blank">Cornell Botanic Gardens</a></li>
            <li><a href="https://cals.cornell.edu/" target="_blank">College of Agriculture &amp; Life Sciences (CALS)</a></li>
            <li><a href="https://scl.cornell.edu/coe" target="_blank">Cornell Outdoor Education</a></li>
            <li><a href="https://www.birds.cornell.edu/home/" target="_blank">Cornell Lab of Ornithology</a></li>
            <li><a href="https://fcs.cornell.edu/departments/energy-sustainability/campus-sustainability-office" target="_blank">Campus Sustainability Office</a></li>
          </ul>

        </div>
      </lib-main-footer>
      <lib-sub-footer></lib-sub-footer>
    </lib-footer>
  </lib-cu-ng-design-library>
  <app-scroll-to-top></app-scroll-to-top>
</div>
} @if(!isPublicLayout() && isAdminUser()) {
<lib-admin-layout
  [routes]="adminRoutes"
  toolbarTitle="Nature Rx"
  imgHeight="24"
>
  <div toolbar class="ssit-toolbar">
    <div class="unit mobile-hidden">Nature Rx | Admin</div>
    <a mat-button href="/api/logout"> Logout </a>
  </div>
</lib-admin-layout>
<lib-footer footer>
  <lib-sub-footer></lib-sub-footer>
</lib-footer>
<app-scroll-to-top></app-scroll-to-top>
}
