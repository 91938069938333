import { RouterModule } from '@angular/router';
import { Component, OnInit, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { OneColumnComponent } from '../../../shared/layout/one-column/one-column.component';
import { ResourcesModel } from '../../../../../server/data/models/resources.model';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ResourceCardComponent } from './resource-card/resource-card.component';

@Component({
  selector: 'app-resources-home',
  standalone: true,
  imports: [
    CommonModule,
    OneColumnComponent,
    MatCardModule,
    NgOptimizedImage,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    ResourceCardComponent,
  ],
  providers: [ResourcesService],
  template: `
    <section
      role="complementary"
      aria-label="Highlighted resources with links to more information"
    >
      <div class="full-flex container-fluid accent pt-4 pb-0 mb-1 ai-c">
        <h3 class="mat-headline-3  m-0 p-0 flex-1">Resources</h3>
        <a routerLink="/resources" mat-stroked-button color="accent">
          <span class="full-flex ai-c">
            More&nbsp;<span class="desktop-only">Resources</span
            ><mat-icon>chevron_right</mat-icon>
          </span>
        </a>
      </div>
      <div class="grid-container">
        @for (resource of prioritizedResources(); track $index) {
        <app-resource-card
          [resource]="resource"
          class="card-{{ $index }}"
        ></app-resource-card>
        }
      </div>
    </section>
  `,
  styles: [
    `
      .accent {
        color: #b31b1b;
      }
      :host {
        background-color: rgb(252, 252, 252);
      }

      .grid-container {
        display: grid;
        grid-gap: 16px;
        padding: 16px;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
      }

      @media (max-width: 1750px) {
        .grid-container .card-6 {
          display: none;
        }
      }

      @media (max-width: 1505px) {
        .grid-container .card-5,
        .grid-container .card-6 {
          display: none;
        }
      }
      @media (max-width: 1260px) {
        .grid-container .card-4,
        .grid-container .card-5,
        .grid-container .card-6 {
          display: none;
        }
      }

      @media (max-width: 1015px) {
        .grid-container .card-3,
        .grid-container .card-4,
        .grid-container .card-5,
        .grid-container .card-6 {
          display: none;
        }
      }

      @media (max-width: 768px) {
        .grid-container .card-2,
        .grid-container .card-3,
        .grid-container .card-4,
        .grid-container .card-5,
        .grid-container .card-6 {
          display: block;
        }

        .grid-container {
          grid-template-columns: 1fr;
        }
      }
    `,
  ],
})
export class ResourcesHomeComponent implements OnInit {
  prioritizedResources = signal<ResourcesModel[]>([]);
  constructor(private resourcesService: ResourcesService) {}

  ngOnInit() {
    this.getResources();
  }

  getResources() {
    if (this.resourcesService.isBrowserOnly) {
      this.resourcesService.getResources().subscribe((resources) => {
        const prioritizedResourceNames = [
          'Cornell Botanic Gardens',
          'Cornell Lab of Ornithology',
          'Cornell Outing Club',
          'Cornell Outdoor Education',
          'Cayuga Waterfront Trail',
          'Cayuga Trails Club',
          'NYS Hiking Trails',
        ];

        // Map the prioritizedResourceNames to their corresponding resource objects
        const prioritizedResources = prioritizedResourceNames
          .map((name) => resources.find((resource) => resource.name === name))
          .filter((resource) => resource !== undefined); // Filter out any undefined results in case a name doesn't match

        this.prioritizedResources.set(prioritizedResources);
      });
    }
  }

  onError(event: any) {
    event.target.src = 'assets/placeholder.jpg';
  }
}
