import { Component, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoute } from '../../../../app/app.routes';
import { Router, RouterModule } from '@angular/router';
import { SecondaryNavComponent } from '../secondary-nav/secondary-nav.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-page-outlet',
  standalone: true,
  providers: [],
  imports: [CommonModule, RouterModule, SecondaryNavComponent],
  template: `
    @if (summaryRoutes.length > 0) {
    <div class="left-column">
      <app-secondary-nav
        [summaryRoutes]="summaryRoutes"
        [navTitle]="navTitle"
        [baseUrl]="currentBaseUrl"
      ></app-secondary-nav>
    </div>
    }

    <div class="right-column">
      <router-outlet></router-outlet>
    </div>
  `,
  // @todo get the right column and left column into their own components
  styles: [
    `
      :host {
        display: flex;
        height: 100%; /* This will make the container take the full height of its parent */
        min-height: calc(100vh - 128px);
        margin: 0;
      }
      .left-column {
        min-width: 248px;
        border-right: rgba(0, 0, 0, 0.13) 1px solid;
      }

      .right-column {
        flex: 1; /* Relative size of the right column */
        /* background-color: rgba(0, 0, 0, 0.05); /* Adjusts only the opacity */
        /* Right column background color can be left as default or set as needed */
        background-color: rgba(0, 0, 0, 0.02);
        gap: 8px;
      }
    `,
  ],
})
export class PageOutletComponent {
  private unsubscribe$ = new Subject<void>();
  navTitle: string | undefined;

  isAdmin?: boolean;
  displayName?: string;
  session?: any;
  timeToExpire?: string;
  intervalId?: number;
  alertShown: boolean = false;
  // get the first / path after admin from location
  parentPath = location.pathname.split('/')[2];
  summaryRoutes: AppRoute[] = [];
  // AdminRoutes.find((r) => r.path === this.parentPath)?.children || [];
  currentBaseUrl: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe
      // filter((event) => event instanceof NavigationEnd)
      ()
      .subscribe(() => {
        this.updateRoutesAndPath();
        // Update the base URL
        const urlSegments = this.router.url.split('/');
        // Assuming the desired base URL segment is the second one (after '/admin')
        this.currentBaseUrl = '/' + urlSegments.slice(0, 3).join('/');
      });

    // Initial update
    this.updateRoutesAndPath();
    const initialUrlSegments = this.router.url.split('/');
    this.currentBaseUrl = '/' + initialUrlSegments.slice(0, 3).join('/');
  }

  private updateRoutesAndPath() {
    // all paths will be in the form of /admin/parentPath/childPath

    this.parentPath = this.router.url.split('/')[2];
    // this.navTitle = AdminRoutes.find((r) => r.path === this.parentPath)
    // 	?.title as string;
    this.summaryRoutes = [];
    // AdminRoutes.find((r) => r.path === this.parentPath)?.children || [];[
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
