<mat-drawer-container autosize>
  <mat-drawer
    #drawer
    [opened]="opened"
    class="sidenav"
    [mode]="mode"
    autosize
    [ngClass]="{ mobile: isMobileLayout }"
  >
    <mat-toolbar class="sidebar-logo" color="primary">
      <a routerLink="/">
        <img
          alt="Cornell University"
          [src]="svgIconUrl"
          height="{{ imgHeight }}"
        />
        {{ title }}
      </a>
    </mat-toolbar>
    <mat-nav-list>
      @for (route of routes; track $index) {
      <a
        mat-list-item
        [routerLink]="route.data['path']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="handleLinkClick()"
        class="menu-link"
      >
        <span class="full-flex align-items-center">
          @if (route.data['icon']) {
          <span>
            <mat-icon matListIcon>
              {{ route.data["icon"] }}
            </mat-icon>
          </span>
          }
          <span class="pl font-size-larger">
            {{
              route.data && route.data["title"]
                ? route.data["title"]
                : route.data["path"] || route.path
            }}
          </span>
        </span>
      </a>
      }
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content autosize>
    <lib-admin-toolbar
      (toggleSidenav)="handleToggleClick()"
      (toggleSidenavNotice)="handleToggleClick()"
    >
      <ng-content select="[toolbar]"></ng-content>
    </lib-admin-toolbar>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
